<template>
  <b-row class="justify-content-center mb-4">
    <b-col md="4">
      <b-card
        :img-src="require('@/assets/images/banner/parallax-4.jpg')"
        img-alt="Profile Cover Photo"
        img-top
        class="card-profile"
      >
        <div class="profile-image-wrapper">
          <div class="profile-image p-0">
            <b-avatar
              size="114"
              variant="light-primary"
              :text="avatarText(user.name)"
            />
          </div>
        </div>
        <h3>{{ user.name }}</h3>
        <h6 class="text-muted">
          {{ user.email }}
        </h6>

        <b-badge
          pill
          class="profile-badge"
          :variant="`light-${userEnableAccountVariant(user.enable)}`"
        >
          {{ user.enable }}

        </b-badge>
        <hr class="mb-2">
      </b-card>
      <b-card class="shadow-sm">
        <b-list-group>

          <b-list-group-item
            active
            class="d-flex justify-content-between align-items-center"
          >
            <span>Informations</span>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>Matricule</h6>
            {{ user.matricule }}
          </b-list-group-item>

          <b-list-group-item
            class="d-flex py-1 justify-content-between align-items-center"
          >
            <h6>Téléphone</h6>
            {{ user.phone }}
          </b-list-group-item>
          <b-list-group-item
            class="d-flex py-1 justify-content-between align-items-center"
          >
            <h6>Adresse</h6>
            {{ user.address }}
          </b-list-group-item>

          <b-list-group-item
            class="d-flex py-1 justify-content-between align-items-center"
          >
            <h6>Date de naissance</h6>
            {{ user.birth_date_full }}
          </b-list-group-item>
        </b-list-group>
      </b-card>

    </b-col>
    <b-col md="8">
      <div class="bg-light shadow-lg p-1 rounded mb-2">
        Copropriétés
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          size="sm"
          pill
          class="float-right mb-1"
        >
          Suspendre le compte
          <feather-icon icon="AlertTriangleIcon" />
        </b-button>
      </div>

      <b-card
        no-body
        class="card-company-table"
      >
        <b-table
          :items="tableData"
          responsive
          :fields="fields"
          class="mb-0"
          show-empty
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template #empty>
            <div class="d-flex justify-content-center">
              <b-img
                fluid
                :style="{width:'50%'}"
                :src="require('@/assets/images/no_result.gif')"
              />
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(roles)="data">

            <b-badge
              v-for="item in data.item.roles"
              :key="`jour-${item.id}`"
              pill
              class="ml-1"
              variant="light-primary"
            >
              {{ item.libelle }}

            </b-badge>
          </template>

        </b-table>
      </b-card>
      <div class="bg-light shadow-lg p-1 rounded mb-2">
        Sociétés
      </div>

      <b-card
        no-body
        class="card-company-table"
      >
        <b-table
          :items="tableData"
          responsive
          :fields="fields"
          class="mb-0"
          show-empty
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template #empty>
            <div class="d-flex justify-content-center">
              <b-img
                fluid
                :style="{width:'50%'}"
                :src="require('@/assets/images/no_result.gif')"
              />
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(roles)="data">

            <b-badge
              v-for="item in data.item.roles"
              :key="`jour-${item.id}`"
              pill
              class="ml-1"
              variant="light-primary"
            >
              {{ item.libelle }}

            </b-badge>
          </template>

        </b-table>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import Ripple from 'vue-ripple-directive'

import {
  BCard, BAvatar, BBadge, BRow, BCol, BListGroup, BListGroupItem, BTable, BSpinner, BImg,
  BButton,

} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import useUsers from '@/composables/userService'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BTable,
    BSpinner,
    BButton,
    BImg,
  },
  directives: {
    Ripple,
  },

  setup() {
    const {
      getUserById, user,
    } = useUsers()

    const userEnableAccountVariant = status => {
      if (status === 'Actif') return 'success'
      if (status === 'Inactif') return 'danger'
      return 'primary'
    }
    const tableData = ref([])
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'copropriete', label: 'Copropriété' },
      { key: 'roles', label: 'Rôles' },
    ]

    onMounted(async () => {
      await getUserById(router.currentRoute.params.id)
    })

    return {
      tableData,
      fields,
      avatarText,
      user,
      userEnableAccountVariant,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
